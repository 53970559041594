<template>
  <div id="homeBanners" v-show="banners.length !== 0">
    <swiper
        slidesPerView="auto"
        :space-between="15"
        :navigation="swiper.navigation"
        :breakpoints="swiper.breakpoints"
        :autoplay='{
          "delay": 5000,
          "disableOnInteraction": false
        }'
    >
      <swiper-slide
          v-for="banner in banners"
          v-bind:key="banner.id"
      >
        <div class="banner ratio"
             :style="
                'background-image:url(' +
                window.Routes.getImage(banner.image, 'preview') +
                ')'
              "
        >
          <div class="body" v-if="banner.shop !== null">
            <router-link :to="'/shops/'+banner.shop.id+'?title=' +banner.shop.name">&nbsp;</router-link>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div v-show="banners.length !== 0" class="swiper-controller navigation swiper-prev Aligner">
      <img src="@/assets/nav_arrow_right.svg"/>
    </div>
    <div v-show="banners.length !== 0" class="swiper-controller navigation swiper-next Aligner">
      <img src="@/assets/nav_arrow_right.svg"/>
    </div>

  </div>
</template>

<script>
import SwiperCore, {Navigation, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

export default {
  name: "HomeBanners",
  components: {Swiper, SwiperSlide},
  data() {
    return {
      swiper: {
        spaceBetween: 15,
        navigation: {
          nextEl: "#homeBanners .swiper-next",
          prevEl: "#homeBanners .swiper-prev",
        },
        breakpoints: {
          // when window width is >= X
          270: {
            slidesPerView: 1.1,
          },
          640: {
            slidesPerView: 1,
          },
          960: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      },
      banners: []
    }
  },
  methods: {
    async getHomeBanners() {
      return new Promise((resolve, reject) => {
        window.axios
            .get(window.Routes.getRoute("homepageBanners"))
            .then(
                (response) => {
                  resolve(response.data.data);
                  this.banners = response.data.data;
                },
                (error) => {
                  this.setErrors(error.response.data.errors);
                  reject(error.response.data.errors);
                }
            );
      });
    }
  },
  created() {
    this.getHomeBanners();
  }
}
</script>

<style lang="scss">
#homeBanners {
  margin-top: map-get($spacings, "seperator");
  position: relative;

  .swiper-controller {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-color: #fff;
    width: 28px;
    height: 28px;
    border-radius: map-get($defaults, "border-radius");
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000029;

    img {
      height: 12px;
    }

    &:hover {
      cursor: pointer;
    }

    &.swiper-next {
      right: -14px;
    }

    &.swiper-prev {
      transform: translateY(-50%) rotate(-180deg);
      left: -14px;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  .banner {
    border-radius: map-get($defaults, "border-radius");
    background-size: cover;
    background-color: map-get($colors, "gray");

    //Ratio
    &:before {
      padding-top: 48.4%;
    }

    .body {
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #homeBanners {
    margin-top: 0;
    margin-bottom: map-get($spacings, "default");

    .swiper-container {
      margin-left: -(map-get($spacings, "default"));
      margin-right: -(map-get($spacings, "default"));

      .swiper-wrapper {
        padding-left: map-get($spacings, "default");
      }
    }

    .swiper-controller {
      display: none;
    }
  }
}
</style>
