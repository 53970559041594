<template>
  <div id="preferences">
    <div class="swiper_holder">
      <div class="swiper-controllers">
        <div
          class="swiper-controller settings Aligner md-0 ms-md-2"
          v-bind:class="{
            glowing:
              categories_preferred_count == 0 && categories_loaded == true,
          }"
        >
          <preferences-settings
            @preferencesUpdated="preferencesUpdated"
          ></preferences-settings>
        </div>
        <div class="swiper-controller navigation swiper-prev Aligner">
          <img src="@/assets/nav_arrow_right.svg" />
        </div>
        <div class="swiper-controller navigation swiper-next Aligner">
          <img src="@/assets/nav_arrow_right.svg" />
        </div>
      </div>
      <div
        class="swiper-body Aligner items-left"
        v-bind:class="{
          skeleton_loader: !swiper_updated && categories_loaded != true,
        }"
      >
        <span
          class="font-size-md"
          v-if="categories_preferred_count == 0 && categories_loaded == true"
        >
          <i class="fas fa-info-circle me-1"></i>
          {{ $t("categories.selectAtLeastOne") }}
        </span>

        <swiper
          v-if="categories_preferred_count != 0"
          slidesPerView="auto"
          :space-between="15"
          :navigation="swiper.navigation"
          @update="swiperUpdated"
        >
          <swiper-slide
            v-for="preference in categories_preferred"
            v-bind:key="preference.id"
            v-bind:style="'background-color: ' + preference.color"
          >
            <router-link
              class="Aligner items-left"
              v-bind:to="'/categories/' + preference.id + '?title='+ preference.name"
            >
              <img
                class="logo"
                v-if="preference.logo != null"
                v-bind:src="window.Routes.getImage(preference.logo)"
              />
              <span class="title font-size-default dotdotdot lines-1">{{
                preference.name
              }}</span>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- /.swiper_holder -->
  </div>
</template>

<script>
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { mapActions, mapGetters } from "vuex";
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
import PreferencesSettings from "./PreferencesSettings.vue";

// install Swiper modules
SwiperCore.use([Navigation]);

export default {
  name: "Preferences",
  components: { Swiper, SwiperSlide, PreferencesSettings },
  data() {
    return {
      categories_loaded: false,
      swiper_updated: false,
      swiper: {
        navigation: {
          nextEl: "#preferences .swiper-next",
          prevEl: "#preferences .swiper-prev",
        },
      },
    };
  },
  methods: {
    ...mapActions(["getCategoriesPreferredJoined", ""]),
    swiperUpdated() {
      this.swiper_updated = true;
    },
    preferencesUpdated(selected_ids) {
      this.$emit("preferencesUpdated", selected_ids);
    },
  },
  mounted() {
    this.getCategoriesPreferredJoined().then(() => {
      this.categories_loaded = true;
    });
  },
  computed: {
    ...mapGetters(["categories_preferred", "access_token"]),
    categories_preferred_count: function () {
      let length = Object.keys(this.categories_preferred).length;
      return length;
    },
  },
};
</script>

<style lang='scss' scoped>
$preferences_swiper_height: 48px;
#preferences {
  padding-top: map-get($spacings, "seperator");
  width: 100%;
  height: auto;
  float: left;

  .swiper_holder {
    width: 100%;
    float: left;

    .swiper-body {
      overflow: hidden;
      position: relative;
      min-height: $preferences_swiper_height;

      .swiper-container {
        width: 100% !important;
        min-height: 100%;

        .swiper-slide {
          padding: map-get($spacings, "small") map-get($spacings, "default");
          border-radius: map-get($defaults, "border-radius");
          width: auto;

          .logo {
            height: 28px;
            margin-right: map-get($spacings, "small");
            float: left;
          }

          .title {
            float: left;
            max-width: 180px;
            color: map-get($colors, "black");
          }

          &:hover {
            .title {
              color: map-get($colors, "black");
            }
          }
        }
      }
    }

    .swiper-controllers {
      width: auto;
      float: right;
      position: relative;

      &:before {
        content: "";
        width: 20px;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff);
        position: absolute;
        top: 0;
        right: 100%;
        z-index: 2;
      }

      .swiper-controller {
        width: $preferences_swiper_height;
        height: $preferences_swiper_height;
        float: left;
        margin-right: map-get($spacings, "small");
        border-radius: map-get($defaults, "border-radius");
        background-color: map-get($colors, "gray");

        &:hover {
          cursor: pointer;
        }

        img {
          height: 22px;
        }

        i {
          font-size: 22px;
          color: map-get($colors, "black");
        }

        &:last-of-type {
          margin-right: 0;
        }

        &.swiper-button-disabled {
          opacity: 0.7;
        }

        &.settings {
          background-color: map-get($colors, "secondary");
        }

        &.navigation {
          img {
            height: 18px;
          }

          &.swiper-prev {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #preferences {
    float: left;
    width: 100%;
    // width: 100%;
    padding-top: 0;
    padding-bottom: map-get($spacings, "medium");
    min-height: $preferences_swiper_height - 10px;
    // position: relative;

    .swiper_holder {
      // width: auto;
      // position: absolute;
      // top: 0;
      // right: -(map-get($spacings, "default"));
      // left: 0;
      .swiper-body {
        min-height: $preferences_swiper_height - 10px;

        .swiper-container {
          .swiper-slide {
            padding: map-get($spacings, "xsmall") map-get($spacings, "small");
          }
        }
      }

      .swiper-controllers {
        width: auto;
        float: left;

        &:before {
          display: none;
        }

        .swiper-controller {
          &.settings {
            width: $preferences_swiper_height - 10px;
            height: $preferences_swiper_height - 10px;
            background-color: map-get($colors, "secondary");
          }

          &.navigation {
            display: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #preferences {
    position: relative;

    .swiper_holder {
      width: auto;
      position: absolute;
      top: 0;
      right: -(map-get($spacings, "default"));
      left: 0;
    }
  }
}
</style>
