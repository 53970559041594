<template>
  <div class="no-products text-center">
    <div class="holder">
      <img class="attention-icon" src="@/assets/attention.svg" alt="Attention">

      <div class="text-uppercase font-size-lg">
        {{ $t("homepage.no_products.title") }}
      </div>
      <div>
        {{ $t("homepage.no_products.subtitle") }}
      </div>

      <div class="buttons">
        <router-link to="/guest-address/create" class="btn btn-primary w-100">
          {{ $t("homepage.no_products.change_address_btn") }}
        </router-link>

        <icon-button @click="openFilters" classes="btn btn-outlined w-100 mt-3" :image="require('@/assets/filter-2.svg')"
                     :text="$t('homepage.no_products.set_filters_btn')"/>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/IconButton";

export default {
  name: "HomepageNoProducts",
  components: {IconButton},
  methods: {
    openFilters(){
      this.$emit("openFilters", true);
    }
  }
}
</script>

<style lang="scss" scoped>
.no-products {
  width: 100%;
  float: left;
  padding-top: map-get($spacings, "xxl");
  padding-bottom: map-get($spacings, "xxl");

  .holder {
    width:100%;
    max-width: 300px;
    margin:0 auto;

    .attention-icon {
      margin-bottom: map-get($spacings, "xxl");
    }

    .buttons {
      margin-top: map-get($spacings, "xxl");
    }
  }
}
</style>
