<template>
  <div class="home">
    <!-- STORIES -->
    <stories-container/>

    <div class="container">
      <!--   BANNERS   -->
      <home-banners/>

      <!-- PREFERENCES -->
      <preferences v-if="access_token !== null" @preferencesUpdated="homepagePreferencesUpdated"/>

      <!-- ACTIVE ORDERS -->
      <mobile-active-orders v-if="access_token !== null"/>

      <!-- FILTERS -->
      <homepage-filters
          :toggleFilters='toggleFilters'
          @filtersUpdated="homepageFiltersUpdated"
          @toggled="filtersToggled"
      />

      <!-- PROMOTION-CARDS -->
      <!--   Removed at the moment   -->

      <!-- PRODUCT-SECTIONS Skeleton Loader -->
      <div v-if="!productSectionsLoaded">
        <div class="row mb-5" v-for="index in 2" :key="index">
          <div class="col-12 skeleton mb-3">
            <div class="line thick skeleton_loader dark w-20"></div>
            <div class="line skeleton_loader dark w-40"></div>
          </div>
          <div
              class="col-6 col-lg-4 col-xl-2 skeleton"
              v-for="index in 6"
              :key="index"
          >
            <div class="ratio ratio-4_3 bg-gray"></div>
            <div class="line skeleton_loader"></div>
            <div class="line skeleton_loader w-50"></div>
            <div class="line skeleton_loader dark w-30 thick"></div>
          </div>
        </div>
      </div>

      <!--   Display message if no products available   -->
      <homepage-no-products
          v-if="homepageProductSections?.length === 0 && productSectionsLoaded === true"
          @openFilters="openFilters"
      />

      <!-- PRODUCT-SECTIONS -->
      <products-section
          v-for="(homepageProductSection, index) in homepageProductSections"
          :key="index"
          :products="homepageProductSection.products"
          :title="homepageProductSection.title"
          :subtitle="homepageProductSection.subtitle"
          :id="homepageProductSection.id"
          :type="homepageProductSection.type"
          :background="homepageProductSection.color"
      />
    </div>

    <!--   Near You   -->
    <div class="near-you-holder">
      <div class="container">
        <router-link class='btn btn-secondary font-size-sm near-you-btn' to="/near-you">
          <i class="fas fa-map me-2"></i> {{ $t("near_you.title") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Preferences from "@/views/Home/partials/Preferences.vue";
import StoriesContainer from "./partials/StoriesContainer.vue";
import ProductsSection from "./partials/ProductsSection.vue";
import HomepageFilters from "./partials/HomepageFilters.vue";
import MobileActiveOrders from "@/views/Home/partials/MobileActiveOrders.vue";
import HomeBanners from "@/views/Home/HomeBanners";
import HomepageNoProducts from "@/views/Home/partials/HomepageNoProducts";

export default {
  name: "HomeShow",
  components: {
    HomepageNoProducts,
    HomeBanners,
    Preferences,
    StoriesContainer,
    ProductsSection,
    HomepageFilters,
    MobileActiveOrders,
  },
  data() {
    return {
      toggleFilters: false,
      productSectionsLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["access_token", "homepageProductSections", "categories"]),
  },
  methods: {
    ...mapActions(["getHomepageProductSections"]),
    filtersToggled(opened) {
      console.log("opened: " + opened);
      this.toggleFilters = opened;
    },
    openFilters() {
      console.log("Open filters now");
      this.toggleFilters = true;
    },
    prepareFiltersData(data) {
      let formData = JSON.parse(JSON.stringify(data));
      if (formData["sort_by_price"] === undefined) {
        formData["sort_by_price"] = [];
      } else {
        formData["sort_by_price"] = [formData["sort_by_price"]];
      }
      let sort_by = [...formData["sort"], ...formData["sort_by_price"]];
      formData["sort_by"] = sort_by;
      return formData;
    },
    getProductSections(formData) {
      this.getHomepageProductSections(formData).then(() => {
        this.productSectionsLoaded = true;
      });
    },
    homepagePreferencesUpdated() {
      this.productSectionsLoaded = false;
      this.getProductSections(this.formData);
    },
    homepageFiltersUpdated(formDate) {
      // This funciton is called when the filters are ready or updated
      // Filters form data will be retrieved by this function
      this.formData = formDate;
      this.productSectionsLoaded = false;
      this.getProductSections(this.formData);
    },
  },
};
</script>

<style lang="scss" scoped>
.near-you-holder {
  width: 100%;
  height: auto;
  z-index: 2;
  position: fixed;
  bottom: 15px;

  .container {
    text-align: right;

    .near-you-btn {

    }
  }
}

</style>

