<template>
  <div id="#stories" v-if="stories.length !== 0">
    <div
      id="storiesNavigation"
      v-bind:class="{
        skeleton_loader: !storiesLoaded,
      }"
    >
      <div class="container">
        <swiper
          slidesPerView="auto"
          :space-between="15"
          :navigation="swiper.navigation"
          :breakpoints="swiper.breakpoints"
        >
          <swiper-slide
            v-show="!storiesLoaded"
            v-for="index in 10"
            v-bind:key="index"
            class=""
          >
            <div class="slide ratio bg-gray"></div>
          </swiper-slide>

          <swiper-slide
            v-for="(story, index) in stories"
            v-bind:key="index"
            @click="storyClicked(index)"
            v-bind:style="'background-color: ' + story.color"
          >
            <div
              class="slide ratio"
              :class="'type-' + story.type"
              :style="
                'background-image:url(' +
                window.Routes.getImage(story.slides[0].image, 'url') +
                ')'
              "
            >
              <div class="body">
                <img
                  class="logo"
                  v-if="story.logo != null"
                  v-bind:src="window.Routes.getImage(story.logo)"
                />
              </div>
              <!-- /.body -->
            </div>
            <!-- /.slide ratio -->
          </swiper-slide>
        </swiper>
        <div
          v-show="storiesLoaded"
          class="swiper-controller navigation swiper-prev Aligner"
        >
          <img src="@/assets/nav_arrow_right.svg" />
        </div>
        <div
          v-show="storiesLoaded"
          class="swiper-controller navigation swiper-next Aligner"
        >
          <img src="@/assets/nav_arrow_right.svg" />
        </div>
      </div>
      <!-- /.container -->
    </div>
    <!-- /#storiesNavigation -->

    <div class="clear"></div>
    <!-- Stories View -->
    <div
      class="stories_overlay"
      :class="{ opened: storiesOpened }"
      @click="closeStories"
    >
      <div class="stories_holder">
        <stories
          v-on:click.stop=""
          :autoplay="false"
          :duration="duration"
          :stories="stories"
          @ended="storiesEnded"
          @swipe_left="swipeLeft"
          @swipe_right="swipeRight"
          @swipe_down="swipeDown"
          @swipe_up="swipeUp"
          width="100%"
          height="100%"
          :breakpoint="768"
          ref="stories_component"
        >
          <template v-slot:slide="{ story, slide }">
            <div
              class="slide"
              :style="
                'background-image:url(' +
                window.Routes.getImage(slide.image, 'url') +
                ')'
              "
            >
              <div class="header">
                <div class="shop font-size-md">
                  <router-link
                    :to="story.type == 'shop' ? '/shops/' + story.id : '/'"
                  >
                    <img
                      class="logo"
                      v-if="story.logo != null"
                      v-bind:src="window.Routes.getImage(story.logo)"
                    />

                    {{ story.name }}
                  </router-link>
                </div>

                <div class="title font-size-md" v-if="slide.product != null">
                  {{ slide.product.name }}
                </div>
              </div>

              <div class="footer">
                <button
                  @click.stop="goToProduct(slide.product.id)"
                  v-if="slide.product != null"
                  class="btn btn-lg font-size-md btn-light"
                >
                  {{ $t("global.view") }} {{ $t("products.title_singular") }}
                </button>
              </div>
              <!-- /.footer -->
            </div>
          </template>
        </stories>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { mapGetters, mapActions } from "vuex";
import Stories from "vue3-insta-stories";

// install Swiper modules
SwiperCore.use([Navigation]);

import { ref } from "vue";
export default {
  components: { Stories, Swiper, SwiperSlide },
  name: "StoriesContainer",
  setup() {
    const stories_component = ref(null);

    return {
      stories_component,
    };
  },
  computed: {
    ...mapGetters(["stories"]),
  },
  data() {
    return {
      storiesLoaded: false,
      duration: 7000,
      storiesOpened: false,
      swiper: {
        spaceBetween: 15,
        navigation: {
          nextEl: "#storiesNavigation .swiper-next",
          prevEl: "#storiesNavigation .swiper-prev",
        },
        breakpoints: {
          270: {
            slidesPerView: 4.5,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 10,
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(["getStories"]),
    goToProduct(product_id) {
      this.$router.push("/products/" + product_id);
    },
    //Actions
    swipeLeft() {
      this.stories_component.nextStory();
    },
    swipeRight() {
      this.stories_component.prevStory();
    },
    swipeUp() {
      this.closeStories();
    },
    swipeDown() {
      this.closeStories();
    },
    storyClicked(index) {
      this.storiesOpened = true;
      this.stories_component.recalculateDimensions();
      this.stories_component.playStory(index);
    },
    closeStories() {
      this.stories_component.resetStory();
      this.storiesOpened = false;
    },
    storiesEnded() {
      this.stories_component.resetStory();
      this.storiesOpened = false;
    },
  },
  created() {
    if (Object.keys(this.stories).length != 0) {
      this.storiesLoaded = true;
    }
    this.getStories().then(() => {
      this.storiesLoaded = true;
    });
  },
};
</script>

<style lang='scss'>
#storiesNavigation {
  width: 100%;
  height: auto;
  float: left;
  background-color: map-get($colors, "lightGray");
  padding-top: map-get($spacings, "default");
  padding-bottom: map-get($spacings, "default");

  .container {
    position: relative;
    .swiper-container {
      //margin-left: map-get($spacings, "default");
      //margin-right: map-get($spacings, "default");
      .swiper-wrapper {
        padding: 5px;
        .slide {
          background-size: cover;
          background-position: center;
          border-radius: 300px;
          &:hover {
            cursor: pointer;
          }

          &.type-system {
            &:after {
              content: "";
              display: block;
              position: absolute;
              top: -5px;
              left: -5px;
              right: -5px;
              bottom: -5px;
              border-radius: 200px;
              border: 2px solid map-get($colors, "twiks");
            }
          }

          .logo {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            bottom: map-get($spacings, "xsmall");
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 30px;
            border: 2px solid map-get($colors, "white");
          }
        }
      }
    }

    .swiper-controller {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      background-color: #fff;
      width: 28px;
      height: 28px;
      border-radius: map-get($defaults, "border-radius");
      overflow: hidden;
      box-shadow: 0px 3px 6px #00000029;

      img {
        height: 12px;
      }

      &:hover {
        cursor: pointer;
      }

      &.swiper-next {
        right: -2px;
      }

      &.swiper-prev {
        transform: translateY(-50%) rotate(-180deg);
        left: -2px;
      }

      &.swiper-button-disabled {
        display: none;
      }
    }
  }
}

.stories_overlay {
  position: fixed;
  top: auto;
  right: 0;
  bottom: -100%;
  left: 0;
  background-color: rgb(30, 63, 77, 0.8);
  z-index: 999;
  width: 100%;
  height: 100%;
  padding-top: map-get($spacings, "seperator");
  padding-bottom: map-get($spacings, "seperator");
  transition: all ease-in-out 200ms;
  margin: 0;
  opacity: 0;

  &.opened {
    opacity: 1;
    bottom: 0;
  }

  .stories_holder {
    width: 100%;
    max-width: 50vh;
    height: 100%;
    margin: 0 auto;
    .story {
      width: 100%;
      height: 100%;
      .slide {
        width: 100%;
        height: 100%;
        border-radius: map-get($defaults, "border-radius");
        background-size: cover;
        background-position: center;
        overflow: hidden;

        .header {
          padding: map-get($spacings, "default") + 10px
            map-get($spacings, "default") map-get($spacings, "default")
            map-get($spacings, "default");
          width: 100%;
          float: left;

          text-transform: uppercase;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.8),
            rgba(255, 255, 255, 0)
          );

          .shop {
            width: 100%;
            float: left;
            color: map-get($colors, "lightGrayHalf");
            font-weight: 300;

            a {
              display: flex;
              justify-content: left;
              align-items: center;
              color: map-get($colors, "lightGrayHalf");
              .logo {
                float: left;
                width: 30px;
                height: 30px;
                object-fit: cover;
                margin-right: map-get($spacings, "small");
                border-radius: 30px;
                border: 2px solid map-get($colors, "white");
              }
            }
          }

          .title {
            width: 100%;
            float: left;
            font-weight: 500;
            color: map-get($colors, "white");
            padding-top: map-get($spacings, "small");
          }
        }

        .footer {
          position: absolute;
          bottom: map-get($spacings, "default");
          left: map-get($spacings, "default");
          right: map-get($spacings, "default");

          .btn {
            width: 100%;
            background-image: url("~@/assets/nav_arrow_right.svg");
            background-size: 7px auto;
            background-repeat: no-repeat;
            background-position: center right map-get($spacings, "default");
          }
        }
      }

      .timeline {
        .slice {
          height: 2px;
          background-color: map-get($colors, "secondary");
          .progress {
            background-color: map-get($colors, "gray");
            height: 2px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #storiesNavigation {
    background-color: transparent;
    .container {
      padding-left: 0;
      padding-right: 0;

      .swiper-controller {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #storiesNavigation {
    .container {
      .swiper-container {
        padding-left: map-get($spacings, "default");
        padding-right: map-get($spacings, "default");
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .stories_overlay {
    padding-top: 0;
    padding-bottom: 0;
    .stories_holder {
      width: 100%;
      max-width: none;
      height: 100%;
      margin: 0 auto;

      .story {
        .slide {
          border-radius: 0;
        }
      }
    }
  }
}
</style>
