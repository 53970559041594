<template>
  <div class="homepageFilter">
    <filter-seperator @openFilters="openFilters" />

    <overlay @overlayClicked="closeFilters" :active="active">
      <div class="filtersWrapper" :class="{ active: active }">
        <div class="header row justify-content-between">
          <div class="col-3 Aligner items-left">
            <button class="btn btn-clean close" @click="closeFilters">
              <i class="fas fa-times-circle color-secondary"></i>
            </button>
          </div>
          <div class="font-size-big font-weight-bold col Aligner">
            {{ $t("global.filters") }}
          </div>
          <div class="col-3 Aligner items-right">
            <button
              @click="resetFilters"
              class="btn btn-link color-black hover-color-black px-0 btn-sm"
            >
              {{ $t("global.reset") }}
            </button>
          </div>
        </div>
        <perfect-scrollbar :options="{ wheelPropagation: false }">
          <products-section-filters
            :defaults="homepage_filters"
            @saved="savedFilters"
            ref="filters"
          />
        </perfect-scrollbar>
      </div>
    </overlay>
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import { ref } from "vue";
import ProductsSectionFilters from "@/components/ProductsSectionFilters.vue";
import FilterSeperator from "@/components/FilterSeperator.vue";
export default {
  setup() {
    const filters = ref(null);

    return {
      filters,
    };
  },
  name: "HomepageFilters",
  components: { Overlay, ProductsSectionFilters, FilterSeperator },
  props: {
    toggleFilters: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      homepage_filters: {
        sort: [],
      },
      active: false,
    };
  },
  watch: {
    toggleFilters(){
      console.log("Fitles opepend");
      this.active = this.toggleFilters === true;
    },
    active(){
      console.log("Active state changed");
      this.$emit('toggled', this.active);
    }
  },
  methods: {
    closeFilters() {
      this.active = false;
    },
    openFilters() {
      this.active = true;
    },
    prepareData(data) {
      let formData = JSON.parse(JSON.stringify(data));
      if (formData["sort_by_price"] == undefined) {
        formData["sort_by_price"] = [];
      } else {
        formData["sort_by_price"] = [formData["sort_by_price"]];
      }
      let sort_by = [...formData["sort"], ...formData["sort_by_price"]];
      formData["sort_by"] = sort_by;
      return formData;
    },
    savedFilters(data) {
      this.homepage_filters = data;
      //Save to the local storage
      localStorage.setItem("filters", JSON.stringify(data));
      this.active = false;

      // PREPARE DATA FOR API
      let formDate = this.prepareData(data);
      this.$emit("filtersUpdated", formDate);
    },
    resetFilters() {
      this.homepage_filters = {
        payment_method: "both",
        sort: [],
        radius: 15,
      };
    },
    checkStorage() {
      //Get Filters from local storage
      let homepage_filters_storage = JSON.parse(
        localStorage.getItem("filters")
      );

      if (homepage_filters_storage != undefined) {
        this.homepage_filters = homepage_filters_storage;
      } else {
        this.homepage_filters = {
          payment_method: "both",
          sort: [],
          radius: 15,
        };
      }

      // PREPARE DATA FOR API
      let formDate = this.prepareData(this.homepage_filters);
      this.$emit("filtersUpdated", formDate);
    },
  },
  created() {
    this.checkStorage();
  },
};
</script>

<style lang='scss' scoped>
.homepageFilter {
  width: 100%;
  float: left;
  position: relative;

  .filterSeperator {
    margin-top: map-get($spacings, "seperator");
    margin-bottom: map-get($spacings, "seperator");
  }

  .filtersWrapper {
    width: 350px;
    position: fixed;
    top: 0;
    right: -100%;
    // right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: #fff;
    box-shadow: rgba(#000, 0.25) -3px 3px 15px;
    transition: all ease-in-out 400ms;
    padding: map-get($spacings, "large") map-get($spacings, "large") 0
      map-get($spacings, "large");

    &.active {
      right: 0;
    }

    .header {
      .btn.close {
        margin-left: -(map-get($spacings, "default"));
      }
    }

    .ps {
      width: 100%;
      height: 100%;
      float: left;
      padding-top: map-get($spacings, "small");
      padding-bottom: map-get($spacings, "large") * 2;
    }
  }
}

@include media-breakpoint-down(sm) {
  .homepageFilter {
    margin-top: 0;
    .filterSeperator {
      margin-bottom: map-get($spacings, "default");
    }
    .filtersWrapper {
      width: 100%;
      border-radius: 0;
    }
  }
}
</style>
