<template>
  <button :class="classes" class="icon-button">
    <img :src="image">
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    classes:{
      type: String,
    },
    image:{
      type: String,
    },
    text:{
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-button{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 15px;
    }

    span {
      display:flex;
      flex-grow: 1;
      justify-content: center;
    }

  }
</style>