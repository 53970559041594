<template>
  <template v-if="categories_preferred_joined_length != 0">
    <confirm-modal
      modalClasses="clean"
      modalDialogClasses="modal-lg"
      confirmBtnClass="btn-primary"
      :confirmBtnDisabled="confirmBtnDisabled"
      declinedBtnClass="btn-default"
      v-bind:confirmBtn="$t('global.save')"
      footerLayout="centered-vertically"
      @confirmed="preferenceSettingsConfirmed"
    >
      <button class="btn w-100 p-0 font-size-default">
        <img src="@/assets/settings.svg" />
      </button>

      <template v-slot:header>
        <div class="modal-title font-size-xl mb-1 font-weight-bold">
          {{ $t("categories.title") }}
        </div>
        <p class="mb-0">{{ $t("categories.select_preferred") }}:</p>
      </template>
      <template v-slot:body>
        <div
          class="form-check cover"
          v-for="category in categories_preferred_joined"
          :key="category.id"
        >
          <input
            class="form-check-input"
            type="checkbox"
            name="preference[]"
            v-bind:value="category.id"
            v-bind:id="'category_' + category.id"
            v-bind:style="'background-color: ' + category.color"
            v-bind:checked="category.selected"
            v-model="selected_ids"
          />
          <label
            class="form-check-label Aligner items-left"
            v-bind:for="'category_' + category.id"
          >
            <img
              class="logo"
              v-if="category.logo != null"
              v-bind:src="window.Routes.getImage(category.logo)"
              alt=""
            />
            <span class="title font-size-default dotdotdot lines-1">{{
              category.name
            }}</span>
          </label>
        </div>
      </template>
    </confirm-modal>
  </template>

  <template v-if="categories_preferred_joined_length == 0">
    <button class="btn w-100 font-size-default p-0" disabled>
      <img src="@/assets/settings.svg" />
    </button>
  </template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ConfirmModal from "@/components/ConfirmModal.vue";
export default {
  components: { ConfirmModal },
  name: "PreferencesSettings",
  emits: ["preferencesUpdated"],
  data() {
    return {
      selected_ids: [],
    };
  },
  methods: {
    ...mapActions(["categoriesSync"]),
    preferenceSettingsConfirmed() {
      this.categoriesSync(this.selected_ids);
      this.$emit("preferencesUpdated", this.selected_ids);
    },
  },
  watch: {
    selected_ids_computed: function () {
      this.selected_ids = this.selected_ids_computed;
    },
  },
  computed: {
    ...mapGetters(["categories_preferred_joined", "categories_preferred"]),
    categories_preferred_joined_length: function () {
      return Object.keys(this.categories_preferred_joined).length;
    },
    confirmBtnDisabled: function () {
      return false;
      // if (this.selected_ids.length != 0) {
      //   return false;
      // } else {
      //   return true;
      // }
    },
    selected_ids_computed: function () {
      return this.categories_preferred.map((preferred_category) => {
        return preferred_category.id;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.form-check {
  margin-bottom: map-get($spacings, "small");
  margin-right: map-get($spacings, "small");

  .form-check-input {
    & + label {
      padding: map-get($spacings, "small") map-get($spacings, "default");
      .logo {
        height: 28px;
        margin-right: map-get($spacings, "small");
        float: left;
      }

      .title {
        float: left;
        max-width: 180px;
        color: map-get($colors, "black");
      }
    }
  }
}
</style>