<template>
  <div class="productsSection">
    <div class="background" :style="'background-color:' + background"></div>
    <div class="header row justify-content-between">
      <div class="col-auto">
        <div class="font-size-lg font-weight-bold">{{ title }}</div>
        <div class="font-size-sm color-secondary">{{ subtitle }}</div>
      </div>
      <div class="col-auto Aligner">
        <router-link
          :to="sectionLink"
          class="text-uppercase color-secondary hover-color-secondary"
          >{{ $t("global.view_more") }}</router-link
        >
      </div>
    </div>

    <div class="body">
      <div class="row">
        <product-card
          class="product col-6 col-lg-4 col-xl-2"
          v-for="product in products"
          :product="product"
          :key="product.id"
        />
        <!-- /.col-3 -->
      </div>
      <!-- /.row -->
    </div>

    <div class="clear"></div>
    <!-- /.clear -->
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
export default {
  components: { ProductCard },
  name: "ProductsSection",
  props: {
    background: {
      type: String,
      default: "transparent"
    },
    id: [Number, String],
    type: String,
    products: Array,
    title: String,
    subtitle: String
  },
  computed: {
    sectionLink: function() {
      if (this.type === "packed") {
        return "/categories/packed/" + this.id + "?title=" + this.title;
      } else if (this.type === "featuredProducts") {
        return "/shops/" + this.$route.params.id + "/featuredProducts" + "?title=" + this.title;
      } else if (this.type === "allProducts") {
        return "/shops/" + this.$route.params.id + "/allProducts" + "?title=" + this.title;
      }
      return "/categories/" + this.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.productsSection {
  clear: both;
  display: block;
  margin-left: -(map-get($spacings, "medium"));
  margin-right: -(map-get($spacings, "medium"));
  padding: map-get($spacings, "medium");
  position: relative;

  & > .background {
    border-radius: map-get($defaults, "border-radius");
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 170px;
  }

  & > .header {
    position: relative;
    z-index: 2;
  }

  & > .body {
    position: relative;
    z-index: 2;
    clear: both;
    width: 100%;
    float: left;
    margin-top: map-get($spacings, "default");

    //CUSTOM ROW IN 5 COLUMNS
    .custom_row {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      margin-left: -(map-get($spacings, "default"));
      margin-right: -(map-get($spacings, "default"));

      & > div {
        width: 20%;
        float: left;
        padding: map-get($spacings, "default");
      }
    }

    // Down Bootstrap LG
    @include media-breakpoint-down(xl) {
      .custom_row {
        & > div {
          width: 33.3333%;
        }
      }
    }

    // Down Bootstrap MD
    @include media-breakpoint-down(md) {
      .custom_row {
        & > div {
          width: 50%;
        }
      }
    }

    .product {
      margin-bottom: map-get($spacings, "default");
    }
  }
}

@include media-breakpoint-down(md) {
  .productsSection {
    margin-left: -(map-get($spacings, "default"));
    margin-right: -(map-get($spacings, "default"));
    padding: map-get($spacings, "default");

    & > .background {
      border-radius: 0;
    }
  }
}
</style>
