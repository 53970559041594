<template>
  <div id="MobileActiveOrders" v-if="active_orders_count !== 0 || active_orders_meta.total === undefined">
    <div class="header row justify-content-between">
      <div class="col-auto text-uppercase color-twiks">
        {{ $t("orders.active_orders") }}
      </div>
      <div class="col-auto text-uppercase color-twiks font-weight-bold">
        {{ active_orders_meta.total }}
      </div>
    </div>

    <swiper
      slidesPerView="auto"
      :space-between="15"
      :breakpoints="swiper.breakpoints"
    >
      <template v-if="active_orders_meta.total === undefined">
        <swiper-slide v-for="(skeleton, index) in [1, 2, 3]" v-bind:key="index">
          <div class="loader">
            <div class="row">
              <div class="col-12">
                <div class="row justify-content-between header">
                  <div class="col-4 skeleton">
                    <div class="line thick skeleton_loader dark"></div>
                  </div>
                  <div class="col-2 skeleton">
                    <div class="line thick skeleton_loader dark"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 skeleton">
                <div class="line thick skeleton_loader dark w-70"></div>
                <div class="line skeleton_loader dark w-30"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </template>

      <template v-if="active_orders_count !== 0">
        <swiper-slide v-for="order in active_orders" v-bind:key="order.id">
          <order-card-slim v-bind:key="order.id" v-bind:order="order" />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { mapGetters } from "vuex";
import OrderCardSlim from "@/components/Orders/OrderCardSlim.vue";
export default {
  name: "MobileActiveOrders",
  components: { Swiper, SwiperSlide, OrderCardSlim },
  data() {
    return {
      swiper: {
        breakpoints: {
          250: {
            slidesPerView: 1.1,
          },
          768: {
            slidesPerView: 1.5,
          },
          // when window width is >= 640px
          992: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["active_orders", "active_orders_meta"]),
    active_orders_count: function () {
      return Object.keys(this.active_orders).length;
    },
  },
};
</script>

<style lang='scss'>
#MobileActiveOrders {
  width: 100%;
  float: left;
  clear: both;
  padding-top: map-get($spacings, "xsmall");
  margin-bottom: map-get($spacings, "seperator");
  display: none;

  .header {
    margin-bottom: map-get($spacings, "xsmall");
  }

  .loader {
    background-color: map-get($colors, "lightestGray");
    padding: map-get($spacings, "small");
    border-radius: map-get($defaults, "border-radius");
  }
}

@include media-breakpoint-down(md) {
  #MobileActiveOrders {
    margin-top: map-get($spacings, "default");
    margin-bottom: map-get($spacings, "default");
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  #MobileActiveOrders {
    .swiper-container {
      margin-left: -(map-get($spacings, "default"));
      margin-right: -(map-get($spacings, "default"));

      .swiper-wrapper {
        padding-left: map-get($spacings, "default");
      }
    }
  }
}
</style>
